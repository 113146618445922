/** ----------------------------------------
    Imports
---------------------------------------- */

import { AppStore } from '@interface/store/App';
import moment from 'moment';

/** ----------------------------------------
    Mutations
---------------------------------------- */

const mutations = {
  selectAmbassadorFilter(state, data) {
    // show ambassador block
    if (state.selectedAmbassador.id !== data.id) {
      state.selectedAmbassador.id = data.id;
      state.selectedAmbassador.index = data.index;
    } else {
      state.selectedAmbassador.id = null;
      state.selectedAmbassador.index = null;
    }
  },
  addToFilters(state, data) {
    let ind = state.selectedFilters[data.type].indexOf(data.filterId);
    ind < 0 ? state.selectedFilters[data.type].push(data.filterId) : state.selectedFilters[data.type].splice(ind, 1);
  },
  resetFilters(state, data) {
    for (var key in state.selectedFilters) {
      state.selectedFilters[key] = [];
    }
    state.selectedAmbassador.id = null;
    state.selectedAmbassador.index = null;
  },
  resetAmbassadorFilters(state, data) {
    state.selectedFilters['ambassador'] = [];
    state.selectedAmbassador.id = null;
    state.selectedAmbassador.index = null;
  },
  filterEventArr(state, allEvents) {
    // This function is for filtering Ambassadors.
    // select ambassador is a different function than filterEvents
    // because it must filter fom an array, bacause an event can be
    // linked to multiple ambassadors.

    const filterTypes = ['ambassador'];
    let filteredEvents = allEvents.data;

    filterTypes.forEach((filterType) => {
      if (state.selectedFilters[filterType].length) {
        let filteredArray = filteredEvents.filter(function (itm) {
          // loop through array: every ambassador that is liked to this event
          let present = false;
          itm[`${filterType}_id`].forEach((el) => {
            state.selectedFilters[filterType].indexOf(el) > -1 && (present = true);
          });
          return present;
        });

        filteredEvents = filteredArray;
      }
    });

    state.filteredEvents.data = filteredEvents;
  },
  filterEvents(state, allEvents) {
    const filterTypes = ['museum', 'category', 'location', 'date', 'genre'];
    let filteredEvents = allEvents.data;

    filterTypes.forEach((filterType) => {
      if (filterType === 'date') {
        let selectedDate = state.selectedFilters[filterType].map((item) => {
          return item;
        });

        selectedDate = selectedDate[0];
        // Today
        if (selectedDate === 1) {
          const setDate = moment().format('YYMMDD');
          let filteredArray = filteredEvents.filter(function (item) {
            return setDate >= moment(item.from.date).format('YYMMDD') && setDate <= moment(item.to.date).format('YYMMDD');
          });

          filteredEvents = filteredArray;
        }
        // This Weekend
        if (selectedDate === 2) {
          const setStartDate = moment()
            .day(0 + 6)
            .format('YYMMDD');
          const setEndDate = moment()
            .day(0 + 7)
            .format('YYMMDD');
          let filteredArray = filteredEvents.filter(function (item) {
            return setStartDate >= moment(item.from.date).format('YYMMDD') && setEndDate <= moment(item.to.date).format('YYMMDD');
          });
          filteredEvents = filteredArray;
        }
        // This week
        if (selectedDate === 3) {
          const setDate = moment().format('YYww');
          let filteredArray = filteredEvents.filter(function (item) {
            return setDate >= moment(item.from.date).format('YYww') && setDate <= moment(item.to.date).format('YYww');
          });
          filteredEvents = filteredArray;
        }
        // This Month
        if (selectedDate === 4) {
          const setDate = moment().format('YYMM');
          let filteredArray = filteredEvents.filter(function (item) {
            return setDate >= moment(item.from.date).format('YYMM') && setDate <= moment(item.to.date).format('YYMM');
          });
          filteredEvents = filteredArray;
        }
        // Next month
        if (selectedDate === 5) {
          const setDate = moment().add(1, 'month').format('YYMM');
          let filteredArray = filteredEvents.filter(function (item) {
            return setDate >= moment(item.from.date).format('YYMM') && setDate <= moment(item.to.date).format('YYMM');
          });
          filteredEvents = filteredArray;
        }
      } else if (state.selectedFilters[filterType].length) {
        filteredEvents = filteredEvents.filter(function (itm) {
          const eventCategories = itm[`${filterType}_id`];
          // Check if any selected filter matches the event's categories
          return eventCategories.some((category) => state.selectedFilters[filterType].includes(category));
        });
      }
    });
    state.filteredEvents.data = filteredEvents;
  },
  setSearchQuery(state, data) {
    state.searchQuery = data;
  },
  setUnfilteredEvents(state, data) {
    state.unfilteredEvents = data;
  },
  setFilteredEvents(state, data) {
    state.filteredEvents.data = data;
  },
  setUnfilteredMuseums(state, data) {
    state.unfilteredMuseums = data;
  },
  setFilteredMuseums(state, data) {
    state.filteredMuseums = data;
  },
  setStartLocation(state, data) {
    state.startLocation = data;
  },
  setActiveHearts(state, data) {
    state.activeHearts.events = data.events;
    state.activeHearts.museums = data.museums;
    state.activeHearts.ambassadors = data.ambassadors;
    state.activeHearts.exhibitions = data.exhibitions;
  },
  removeFromActiveHearts(state, data) {
    let indOf = state.activeHearts[data.type].indexOf(data.id);
    if (indOf >= 0) {
      state.activeHearts[data.type].splice(indOf, 1);
    }
  },
  addToActiveHearts(state, data) {
    let indOf = state.activeHearts[data.type].indexOf(data.id);
    if (indOf < 0) {
      state.activeHearts[data.type].push(data.id);
    }
  },
  setNavData(state, data) {
    state.navData = data;
  },
  startReloadMyNightButton(state) {
    state.reloadMyNightButton = true;
    setTimeout(() => {
      state.reloadMyNightButton = false;
    }, 1);
  },
  setCookieBannerStrings(state, data) {
    state.cookieBanner['accept'] = data.accept;
    state.cookieBanner['deny'] = data.deny;
    state.cookieBanner['info_btn'] = data.info_btn;
    state.cookieBanner['info_url'] = data.info_url;
    state.cookieBanner['text'] = data.text;
  },
  toggleWarning(state, onOff) {
    state.warningVisible = onOff;
  },
  triggerAcceptWarning(state, onOff) {
    state.acceptingWarning = onOff;
  },
  setWarningId(state, id) {
    state.warningId = id;
  },
  setEventType(state, type) {
    state.eventType = type;
  }
};

/** ----------------------------------------
    Export Mutations
---------------------------------------- */

export default mutations;
